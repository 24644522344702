(function ($) {
  Drupal.behaviors.carouselFormatterV1 = {
    attach: function (context) {
      var $template = $('.js-carousel-formatter-v1', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $template.each(function () {
        var $self = $(this);
        var $carousel = $self.find('.js-carousel-formatter__slides');
        var $arrowsDiv = $self.find('.js-carousel-controls');
        var $arrowButtons = $self.find('.js-slick-button');
        var $prevArrow = $self.find('.js-slick-prev-button');
        var $nextArrow = $self.find('.js-slick-next-button');
        var $dotsDiv = $self.find('.carousel-dots');
        var basicSlide = '.js-carousel__slide';
        var $slide = $self.find(basicSlide);
        var autoplaySpeed = parseInt($carousel.data('slides-autoplay-speed'));
        var slidesToShow = parseInt($carousel.data('slides-show'));
        var slidesToShowPc = parseInt($carousel.data('slides-show-pc'));
        var enableDots = $dotsDiv.data('dots-enable');
        var slidesCount = $slide.length || 0;
        var showArrows = slidesCount > slidesToShow ? true : false;
        var showArrowsPc = slidesCount > slidesToShowPc ? true : false;
        // autoplay settings
        var autoplay = $carousel.attr('data-slides-autoplay');

        if (typeof autoplay === 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }
        if (showArrows === false) {
          $arrowsDiv.addClass('mobile-hide-arrows');
        }
        if (showArrowsPc === false) {
          $arrowsDiv.addClass('pc-hide-arrows');
        }
        var settings = {
          arrows: showArrowsPc,
          dots: enableDots,
          appendDots: $dotsDiv,
          slide: basicSlide,
          infinite: true,
          lazyLoad: 'ondemand',
          slidesToShow: slidesToShowPc,
          slidesToScroll: 1,
          autoplay: autoplay,
          autoplaySpeed: autoplaySpeed,
          pauseOnFocus: true,
          prevArrow: $prevArrow,
          nextArrow: $nextArrow,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows: showArrows,
                slidesToShow: slidesToShow,
                slidesToScroll: 1
              }
            }
          ]
        };

        if ($self.hasClass('slick-initialized')) {
          try {
            $self.slick('destroy');
          } catch (e) {
            console.log(e);
          }
        }

        $self.on('setPosition', function () {
          var $arrowAlignmentContainer = $carousel.find('.js-content-block-carousel-arrow-alignment');

          if ($arrowAlignmentContainer.length && $self.hasClass('js-carousel-formatter--overlap-style')) {
            var totalDotsHeight = $arrowAlignmentContainer.height();
            var halfArrowHeight = $arrowAlignmentContainer.height() / 2;

            $arrowButtons.css({
              top: halfArrowHeight
            });
            $dotsDiv.css({
              top: totalDotsHeight
            });
          }
        });

        // Init this carousel with our settings
        $carousel.slick(settings);
      });
    }
  };
})(jQuery);
